import { useMemo } from 'react';
import rootReducer from '@/ducks/index';
import {
    AnyAction
    , configureStore
    , Store
} from '@reduxjs/toolkit';
import apiService from '@/utils/apiService';
import {
    persistReducer
    , persistStore
    , Transform
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {
    decryptStore
    , encryptStore
} from './encryption';

let store: Store<any, AnyAction> | undefined;

const encryptTransform: Transform<any, string> = {
    in: ( state: any ) => {
        if ( !state ) {
            return '';
        }
        const ciphertext = encryptStore( state );
        return ciphertext;
    }
    , out: ciphertext => {
        if ( !ciphertext ) {
            return null;
        }
        const decryptedStore = decryptStore( ciphertext );
        return decryptedStore;
    }
};

const persistConfig = {
    key: 'root'
    , storage
    , whitelist: [ 'user' ]
    , transforms: [ encryptTransform ]
};

const persistedReducer = persistReducer( persistConfig, rootReducer );

function initStore ( initialState: any ) {
    return configureStore( {
        reducer: persistedReducer
        , middleware: getDefaultMiddleware => getDefaultMiddleware( { serializableCheck: false } ).concat(
            apiService
        )
        , devTools: true
        , preloadedState: initialState
    } );
}

export const initializeStore = ( preloadedState: any ) => {
    let initialStore = store ?? initStore( preloadedState );

    // After navigating to a page with an initial Redux state, merge that state
    // with the current state in the store, and create a new store
    if ( preloadedState && store ) {
        initialStore = initStore( {
            ...store.getState()
            , ...preloadedState
        } );
        // Reset the current store
        store = undefined;
    }

    // For SSG and SSR always create a new store
    if ( typeof window === 'undefined' ) return initialStore;
    // Create the store once in the client
    if ( !store ) store = initialStore;

    return initialStore;
};

export function useStore ( initialState: any ) {
    const currentStore = useMemo( () => initializeStore( initialState ), [ initialState ] );
    return currentStore;
}

export const persistor = persistStore( store ?? initializeStore( {} ) );

export default store;
