import CryptoJS from 'crypto-js';

const ENCRYPTION_KEY = process.env.NEXT_PUBLIC_ENCRYPTION_KEY;

export const encryptStore = ( state: any ) => {
    if ( !ENCRYPTION_KEY ) {
        return 'No encryption key found';
    }
    return CryptoJS.AES.encrypt( JSON.stringify( state ), ENCRYPTION_KEY ).toString();
};

export const decryptStore = ( ciphertext: string ) => {
    if ( !ENCRYPTION_KEY ) {
        return 'No encryption key found';
    }
    try {
        const bytes = CryptoJS.AES.decrypt( ciphertext, ENCRYPTION_KEY );
        return JSON.parse( bytes.toString( CryptoJS.enc.Utf8 ) );

    } catch ( error ) {
        return ciphertext;
    }
};
